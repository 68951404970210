<template>
  <!-- START Bootsrap nav bar -->
  <nav class="navbar navbar-expand-lg shadow navbar-light fixed-top bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img
          src="@/assets/bg.png"
          width="140"
          height="60"
          alt="KeynwynBooks_Logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-2">
            <router-link class="nav-link" to="/" @click="closeMenu"
              ><b>HOME</b>
            </router-link>
          </li>
          <li class="nav-item px-2">
            <router-link class="nav-link" to="/about-page" @click="closeMenu"
              ><b>ABOUT</b>
            </router-link>
          </li>
          <!-- <li class="nav-item px-2">
            <router-link class="nav-link" to="/service-page"
              ><b>Services</b>
            </router-link>
          </li>-->
          <li class="nav-item px-2">
            <router-link class="nav-link" to="/features" @click="closeMenu"
              ><b>FEATURES </b></router-link
            >
          </li>
          <li class="nav-item px-2">
            <router-link class="nav-link" to="/pricing" @click="closeMenu"
              ><b>PRICING</b></router-link
            >
          </li>
          <!-- <li class="nav-item px-2">
            <router-link class="nav-link" to="/partner-page"
              ><b>PARTNER</b></router-link
            >
          </li> -->
          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/partners">Partners </router-link>
          </li>-->

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Blog</a>
              <a class="dropdown-item" href="#">Guide</a>
            </div>
          </li>-->
          <li class="nav-item px-2">
            <router-link
              class="nav-link"
              to="/contact-us-page"
              @click="closeMenu"
              ><b>CONTACT</b>
            </router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <b>SUPPORT </b>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li>
                <a class="dropdown-item" href="#" @click="closeMenu"
                  >SUBMIT A REQUEST</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="http://kb.kenwynbooks.com/"
                  @click="closeMenu"
                  >KNOWLEDGE BASE</a
                >
              </li>
              <li>
                <a class="dropdown-item" href="#" @click="closeMenu"
                  >TRAINING</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item px-2">
            <a
              href="https://app.KenwynBooks.com/"
              target="_blank"
              class="nav-link"
              @click="closeMenu"
              ><b>LOGIN</b></a
            >
          </li>
        </ul>
        <!--<form class="form-inline my-2 my-lg-0">
          <button class="btn btn-success" type="submit">Try it Free</button>
        </form>-->
      </div>
    </div>
  </nav>
  <!-- END Bootsrap nav bar -->
</template>
<script>
export default {
  name: "HeaderNavBar",
  methods: {
    closeMenu() {
      if (window.innerWidth < 992) {
        document.querySelector(".navbar-collapse").classList.remove("show");
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  padding-left: 20px;
  padding-right: 20px;
}
.nav-item {
  font-size: 14px;
}

.dropdown-menu li {
  position: relative;
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 60%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}
</style>
