import { createApp } from "vue";
import App from "./App.vue";

import axios from "axios";

import router from "./router";

//import { LoadingPlugin } from "vue-loading-overlay";
//import "vue-loading-overlay/dist/css/index.css";

const app = createApp(App).use(router);

app.mount("#app");
app.config.devtools = true;

axios.defaults.headers.common["Content-Type"] = "application/json";
