<template>
  <!--abou image-->
  <!-- <div class="img-gradient">
    <img class="first-slide" src="@/assets/book1.jpg" />
  </div>
  <div class="bg-text">
    <h2 class="head"><b>PRICING</b></h2>
    <p class="pt-3 aboutPara"></p>
  </div> -->
  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book1.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>PRICING</b>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <!--abou image-->
  <div class="container">
    <div class="accounding pt-5">
      <b class="pt-5">Select a plan that suits your business needs.</b>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12 col-lg-4 pt-5 pb-3">
        <div class="card border-success" style="height: 37rem">
          <div class="card-body">
            <h4><b>Invoicing</b></h4>
            <p class="str_pack pt-2"><b>(Unlimited Users)</b></p>
            <hr />
            <h5 class="card-title pb-3">
              <span class="str_pack"><b>S$4 </b></span>/ <b> Per Month</b>
            </h5>
            <b>SALES INVOICING</b>
            <p class="card-text">
              Create Unlimited Invoices<br />
              Create Unlimited Quotations<br />
              Credit Note<br />
            </p>
          </div>
          <div class="pt-1">
            <!-- <button href="/features" type="button" class="btn btn-success">Register</button> -->
            <!-- <a href="/register/?plan=free" class="btn btn-success">Register</a> -->
            <router-link class="btn btn-success" to="/register/?plan=free"
              >Register</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 pt-5 pb-3">
        <div class="card border-warning" style="height: 37rem">
          <div class="card-body">
            <h4><b>Accounting</b></h4>
            <p class="acc_pack pt-2"><b>(Unlimited Users)</b></p>
            <hr />
            <h5 class="card-title pb-3">
              <span class="acc_pack"><b>S$36</b></span> / <b>Per Month</b>
            </h5>
            <b>ACCOUNTING</b><br />
            <p class="card-text">
              Sales Invoicing<br />
              Accounts Receivable<br />
              Accounts Payable<br />
              Purchase Order<br />
              Credit Note<br />
              Request for Quotation<br /><br />
              <b>COMPLIANCE</b><br />
              Tax computation<br />
              Annual Report
            </p>
          </div>
          <div class="pt-1">
            <!-- <button href="/features" type="button" class="btn btn-success">
              Register
            </button> -->
            <!-- <a href="/register/?plan=free" class="btn btn-success">Register</a> -->
            <router-link class="btn btn-success" to="/register/?plan=free"
              >Register</router-link
            >
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 col-lg-4 pt-5 pb-3">
        <div class="card border-danger" style="height: 37rem">
          <div class="card-body">
            <h4><b>Human Resource</b></h4>
            <p class="hr_pack pt-2"><b>(Unlimited Users)</b></p>
            <hr />
            <h5 class="card-title pb-3">
              <span class="hr_pack"><b>S$84</b> </span>/ <b>Per Month</b>
            </h5>
            <b>HR</b><br />
            <p class="card-text">
              Appraisal<br />
              Itemized Payslip<br />
              CPF computation<br />
              Attendance management<br />
              Employee claims<br />
              Leave management<br />
              Exployee tax
            </p>
          </div>
          <div class="pt-1">
            <!-- <button href="/features" type="button" class="btn btn-success"> 
              Register
            </button> -->
            <!-- <a href="/register/?plan=free" class="btn btn-success">Register</a> -->
            <router-link class="btn btn-success" to="/register/?plan=free"
              >Register</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container-fluid {
  width: 100%;
}
h1 {
  padding-top: 150px;
  padding-bottom: 50px;
  font-size: 50px;
  color: rgb(8, 147, 29);
  text-align: center;
}
.head {
  font-size: 50px;
  text-align: center;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}
.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
/* .img-gradient {
  position: relative;
  display: inline-block;
} */

/* .img-gradient img {
  display: block;
} */

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.card {
  text-align: center;
  padding-bottom: 20px;
}
/* .row {
  padding-bottom: 50px;
  margin-left: 100px;
} */
h4 {
  color: rgb(0, 0, 0);
}
.str_pack {
  color: rgb(8, 147, 29);
}
.acc_pack {
  color: rgb(200, 141, 13);
}
.hr_pack {
  color: rgb(188, 21, 21);
}

.accounding {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}
@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}
</style>
