<template>
  <!--service image-->
  <!-- <div>
    <img class="first-slide" src="@/assets/bok.jpg" />
  <div class="bg-text">
    <h2 class="head"><b>SERVICES</b></h2>
    <p class="pt-5"></p>
  </div>
  </div> -->

  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book8.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>TERMS OF SERVICE</b>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <!--service image-->
  <!--color page-->
  <!-- <div class="row pt-5 pb-5 rowColor">
    <div class="col-md-8 col-sm-8 col-lg-8 rowClr">
      <div>
        <h5 class="conQus pl-2"><b>HAVE QUESTIONS? LET US KNOW!</b></h5>
        <div>
          <p class="pl-2">
            Have questions on your accounting, corporate secretarial and
            corporate and income tax matters? Book and receive a free
            consultation session with us today! Let us hear you and be your
            solutions in every of your accounting, corporate secretarial and
            income tax matters.
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-4 col-lg-4 pl-5 rowClr">
      <div class="pt-5">
        <button type="button" class="btn btn-outline-light">CONTACT US</button>
      </div>
    </div>
  </div> -->
  <div class="m-3">
    <div class="row pt-5 pl-3">
      <div class="col-md-12 col-sm-12 col-lg-12">
        <div class="accounding pt-5">
          <b class="pt-5">TERMS OF SERVICE</b>
        </div>
        <div style="margin: 40px" class="terms">
          <h4>1.INTRODUCTION</h4>
          <br />
          <p><b>1.1 Welcome</b></p>
          <p>
            1.1.1 &nbsp;Welcome to the terms of use (“Agreement” or “Terms of
            Service”) offered by Kenwyn Accounting and IT Services Pte Ltd and
            its affiliates (“Kenwyn Accounting and IT Services Pte Ltd”, “we”,
            “us” or “our”).

            <br />
            <br />
            1.1.2 &nbsp;This Agreement, together with any amendments, order
            forms, and any additional agreements is a legally binding contract
            that is entered into by the person or entity (“you”, “your”, or
            “user”) who registers with Kenwyn Accounting and IT Services Pte Ltd
            to have access to and use of websites, services, products and
            applications (collectively, the “Kenwyn Accounting and IT Services
            Pte Ltd”). These Terms of Service apply to all visitors, users and
            others who access or use Kenwyn Accounting and IT Services Pte Ltd.

            <br />
            <br />
            1.1.3 &nbsp;The Agreement is effective as of the date of the email
            sent to you by us expressly confirming acceptance of your
            registration or the date you accept the Agreement as part of our
            online subscription process.
            <br />
            <br />
            1.1.4&nbsp; Please read this Agreement carefully.
          </p>

          <br />
          <p><b>1.2&nbsp; Acceptance of Terms of Service</b></p>
          <p>
            1.2.1 &nbsp;By registering with us or accessing or using Kenwyn
            Accounting and IT Services Pte Ltd in any way made available by
            Kenwyn Accounting and IT Services Pte Ltd, you, are indicating your
            acceptance of this Agreement and your agreement to abide by the
            Terms of Service and all other rules, policies and procedures that
            are published on the website.
            <br />
            <br />
            1.2.2 &nbsp;If you are entering into this Agreement on behalf of a
            company, organization, government, or other legal entity, you
            represent and warrant that you are authorised to do so.

            <br />
            <br />
            1.2.3&nbsp; If you do not agree with (or cannot comply with) the
            Agreement, then you may not access or use Kenwyn Accounting and IT
            Services Pte Ltd.
          </p>

          <br />
          <p><b>1.3 &nbsp;Modifications to Terms of Service</b></p>
          <p>
            1.3.1 &nbsp;We reserve the right, at our sole discretion, to make
            modifications to any portion of the Agreement in whole or in part,
            at any time without giving you prior notice. Your use of the
            Services following any such modification constitutes your acceptance
            to be bound by the Agreement as modified.
            <br />
            <br />
            1.3.2 &nbsp;You agree that we shall not be liable to you or to any
            third party for any modifications to the Terms of Service. If you do
            not wish to continue using Kenwyn Accounting and IT Services Pte Ltd
            under the new version of the Agreement, you may choose to terminate
            the Agreement.
            <br />
          </p>

          <br />
          <p><b>1.4 &nbsp;Information</b></p>
          <p>
            1.4.1 &nbsp;The information, material and content provided in the
            pages of Kenwyn Accounting and IT Services Pte Ltd(the
            “Information”) is believed to be reliable when posted, but there is
            no guarantee that it is accurate, complete or current at all times.
            <br />
          </p>
          <br />
          <br />
          <h4>
            2.&nbsp; SERVICES (“Kenwyn Accounting and IT Services Pte Ltd”)
          </h4>
          <br />
          <p>
            <b
              >2.1&nbsp; Usage and Provision of Kenwyn Accounting and IT
              Services Pte Ltd</b
            >
          </p>
          <p>
            2.1.1&nbsp; You may access and use Kenwyn Accounting and IT Services
            Pte Ltd only in compliance with these Terms of Service and in
            accordance with the instructions that we provide. You must use
            Kenwyn Accounting and IT Services Pte Ltd in a lawful manner and you
            must adhere to all applicable laws, rules and regulations.

            <br />
            <br />
            2.1.2 &nbsp;We may suspend or stop providing Kenwyn Accounting and
            IT Services Pte Ltd to you if you do not comply with our terms and
            conditions and if your actions constitute in any way to be
            unauthorised, fraudulent, or illegal purpose.
            <br />
            <br />
            2.1.3 &nbsp;You are responsible for all activities that occur under
            your Account, regardless of whether the activities are undertaken by
            you, your employees or a third party and, except to the extent
            caused by our breach of this Agreement, we and our affiliates are
            not responsible to you or any third party for unauthorised access to
            and use of your Account.
            <br />
            <br />
            2.1.4&nbsp; You shall not
            <br />
            <br />
            1.&nbsp;Modify, translate, or create derivative works based on
            Kenwyn Accounting and IT Services Pte Ltd;<br />
            2.&nbsp;Create any link to Kenwyn Accounting and IT Services Pte Ltd
            or copy any content contained therein;<br />
            3.&nbsp;Reverse engineer, de-compile, disassemble or otherwise
            attempt to discover the source code or underlying ideas of Kenwyn
            Accounting and IT Services Pte Ltd;<br />
            4.&nbsp;Access Kenwyn Accounting and IT Services Pte Ltd to build a
            competitive product or service, or copy any features, functions or
            graphics of Kenwyn Accounting and IT Services Pte Ltd; or<br />
            5.&nbsp;Perform any action that would interfere with the normal
            operations of Kenwyn Accounting and IT Services Pte Ltd or affect
            the use of Kenwyn Accounting and IT Services Pte Ltd by our other
            users.
          </p>
          <br />
          <br />
          <p><b>2.2&nbsp; Registration and Security</b></p>
          <p>
            2.2.1&nbsp; To access and use Kenwyn Accounting and IT Services Pte
            Ltd, you must register and create a Kenwyn Accounting and IT
            Services Pte Ltd User account (the “Account”). The registration
            formality requires you to provide current, complete and accurate
            details about you that we require. You agree to keep the information
            in your Account updated.
            <br />
            <br />
            2.2.2&nbsp; As part of the registration process, you will be
            requested to identify a username and password that will be used to
            set up the Account. You must ensure that the username and password
            required to access and use Kenwyn Accounting and IT Services Pte Ltd
            are kept secure and confidential. You must immediately notify Kenwyn
            Accounting and IT Services Pte Ltd of any unauthorised use of your
            username or password or any other breach of security.
            <br />
            <br />
            2.2.3 &nbsp;You acknowledge that we may use the details submitted by
            you to verify any other information you provide to us. You
            acknowledge and consent to our personnel logging in to Kenwyn
            Accounting and IT Services Pte Ltd under your Account in order to
            maintain or improve Kenwyn Accounting and IT Services Pte Ltd or to
            provide assistance with technical or billing issues.
          </p>

          <br />
          <br />
          <p><b>2.3&nbsp; Free Trial</b></p>
          <p>
            2.3.1&nbsp;We may make all or part of Kenwyn Accounting and IT
            Services Pte Ltd available to you on a trial basis free of charge
            (the “Free Trial”). The Free Trial shall begin when you submit a
            registration for the same to us, and shall terminate on the earlier
            of:
            <br />
            <br />
            1.&nbsp;The Free Trial expiration date as specified by us upon
            receiving your registration;<br />
            2.&nbsp;the date you purchase a paid subscription under this
            Agreement.
            <br />
            <br />
            2.3.2 &nbsp;Notwithstanding clause 5, access to Kenwyn Accounting
            and IT Services Pte Ltd is provided “as-is” and without warranty of
            any kind during the free trial.
          </p>
          <br />
          <br />
          <p><b>2.4&nbsp; Subscription</b></p>
          <p>
            2.4.1&nbsp; Kenwyn Accounting and IT Services Pte Ltd shall make
            Kenwyn Accounting and IT Services Pte Ltd available to you pursuant
            to this Agreement during the Term (the “Subscription”).
            <br />
          </p>
          <br />
          <br />
          <p><b>2.5 &nbsp;Fees and Payments</b></p>
          <p>
            2.5.1&nbsp; The pricing information and payment rates (the “Fees”)
            that you are obligated to pay are set forth on the website. The Fees
            paid are non-refundable.
            <br />
            <br />
            2.5.2 &nbsp;Unless otherwise provided for, paid subscriptions will
            be billed in advance on a monthly or annual basis.
            <br />
            <br />
            2.5.3 &nbsp;A valid credit card that you have the right to use is
            required for any paid subscription, unless another form of payment
            is permitted by us.
            <br />
            <br />
            2.5.4&nbsp; Pricing/ Fee/ Subscription changes will be communicated
            in advance of 30 days via notices outlined in Section 10.2.
          </p>

          <br />
          <br />
          <p><b>2.6 &nbsp;Support</b></p>
          <p>
            2.6.1 &nbsp;We will provide support via email or ‘live-chat” in
            connection with a paid subscription using Kenwyn Accounting and IT
            Services Pte Ltd subject to the conditions and procedures.
          </p>

          <br />
          <br />
          <p>
            <b
              >2.7&nbsp; Changes to Kenwyn Accounting and IT Services Pte Ltd</b
            >
          </p>
          <p>
            2.7.1 &nbsp;We may continue to revise or modify Kenwyn Accounting
            and IT Services Pte Ltd over time as more features are introduced.
            We may stop, suspend, or modify Kenwyn Accounting and IT Services
            Pte Ltd at any time without prior notice to you. We may also remove
            any content (including your Client Data) from Kenwyn Accounting and
            IT Services Pte Ltd at our sole discretion. We also may impose
            restrictions on the usage for Kenwyn Accounting and IT Services Pte
            Ltd(in case of security issues or if we reasonably suspect misuse of
            Kenwyn Accounting and IT Services Pte Ltd) as we, in our sole
            discretion, determine to be applicable and appropriate.
          </p>
          <br />
          <br />
          <h4>3. &nbsp;PROPRIETARY RIGHTS</h4>
          <br />
          <p><b>3.1 &nbsp;Intellectual Property</b></p>
          <p>
            3.1.1 &nbsp;All intellectual property rights in Kenwyn Accounting
            and IT Services Pte Ltd and all intellectual property rights
            relating to the provision of support are owned or licensed by us.
            Except for the subscription granted hereunder, nothing in this
            Agreement gives you any right, title or interest to Kenwyn
            Accounting and IT Services Pte Ltd or related support.

            <br />
            <br />
            3.1.2 &nbsp;We grant you a nonexclusive and non-transferable licence
            to electronically access and use Kenwyn Accounting and IT Services
            Pte Ltd only in the manner described in this Agreement.
            <br />
            <br />
            3.1.3&nbsp; Your comments or suggestions about improvements to
            Kenwyn Accounting and IT Services Pte Ltd or any other component of
            our products or services (“Feedback”), provided to us are without
            any restrictions on our use of it. You also agree that we have no
            fiduciary or any other obligation to you in connection with any
            Feedback you submit to us, and that we are free to use your Feedback
            without any compensation to you.
            <br />
          </p>
          <br />
          <br />

          <h4>4.&nbsp;TERM AND TERMINATION</h4>
          <br />
          <p><b>4.1&nbsp; Term and Termination</b></p>
          <p>
            4.1.1 &nbsp;The Agreement is effective upon the date you first
            access or use Kenwyn Accounting and IT Services Pte Ltd and it will
            continue to apply to you until terminated by either you or us.

            <br />
            <br />
            4.1.2&nbsp; We may terminate the Agreement or suspend your access to
            and use of Kenwyn Accounting and IT Services Pte Ltd without
            liability at any time, including in the event of:
            <br />
            <br />
            1.&nbsp;Your actual or suspected unauthorised or prohibited use of
            Kenwyn Accounting and IT Services Pte Ltd;<br />
            2.&nbsp;Failure to comply with the provisions of the Agreement;<br />
            3.&nbsp;Any requirement by law or a regulatory or government
            body;<br />
            4.&nbsp;Protecting our network or our other users.

            <br />
            <br />
            4.1.3 &nbsp;We may terminate the Agreement with immediate effect if
            you:
            <br />
            <br />
            1.&nbsp;Are unable to pay your debts; or<br />
            2.&nbsp;Enter into compulsory or voluntary liquidation; or<br />
            3.&nbsp;Have a receiver or manager or an administrator appointed.
            <br />
            <br />
            4.1.4 &nbsp;Nothing under this Section shall be deemed as preventing
            us from seeking injunctive or other equitable relief from the courts
            as necessary to protect any of our proprietary interests. Except as
            otherwise provided in these Terms of Service, all remedies are
            cumulative and in addition to (not in lieu of) any other remedies
            available is to at law or equity.
          </p>
          <br />
          <br />
          <p><b>4.2&nbsp; Effects of Termination</b></p>
          <p>
            4.2.1 &nbsp;You acknowledge and agree that the perpetual licence
            granted by you in relation to Client Data, including feedback, is
            irrevocable and will therefore continue after expiry or termination
            of the Agreement for any reason.

            <br />
            <br />
            4.2.2 &nbsp;If you or Kenwyn Accounting and IT Services Pte Ltd
            terminate the Agreement, or if we suspend your access to Kenwyn
            Accounting and IT Services Pte Ltd, you agree that we shall have no
            liability or responsibility to you and we will not refund any
            amounts that you have already paid, to the fullest extent permitted
            under applicable law.
            <br />
            <br />
            4.2.3 &nbsp;Clauses of the Agreement, either explicitly or by their
            nature, must remain in effect even after termination of the
            Agreements.
            <br />
          </p>

          <br />
          <br />
          <h4>5. &nbsp;WARRANTIES AND DISCLAIMERS</h4>
          <br />
          <p><b>5.1&nbsp; Warranties</b></p>
          <p>
            5.1.1&nbsp; Kenwyn Accounting and IT Services Pte Ltd, Is provided
            “as is”, and to the fullest extent permitted by law, neither Kenwyn
            Accounting and IT Services Pte Ltd , its agents, affiliates,
            licensors, nor suppliers, make any warranty of any kind, whether
            express, implied, statutory or otherwise, including warranties of
            merchantability, fitness for a particular use, and non-infringement.
            Kenwyn Accounting and IT Services Pte Ltd makes no representations
            about any content or information in, or from, a Kenwyn Accounting
            and IT Services Pte Ltd user account.
            <br />
            <br />
            5.1.2 &nbsp;We are not responsible for the accuracy, completeness,
            appropriateness, or legality of details, or any other information
            posted by a user
            <br />
            <br />
            5.1.3 &nbsp;We are not responsible for any harm to your computer
            system, loss or corruption of Client Data, or other harm that
            results from your access to or use of Kenwyn Accounting and IT
            Services Pte Ltd.

            <br />
            <br />
            5.1.4 &nbsp;We warrant that the performance relating to Kenwyn
            Accounting and IT Services Pte Ltd will be carried out in a
            competent and work man like manner and in accordance with applicable
            industry standards for similar types of services.
          </p>
          <br />
          <br />

          <p><b>5.2&nbsp; Disclaimer</b></p>
          <p>
            5.2.1&nbsp; We disclaim any and all warranties not expressly stated
            in the Agreement to the maximum extent permitted by law, including
            the implied warranties relating to satisfactory quality and fitness
            for a particular purpose. You are solely responsible for the
            suitability of the services chosen.
            <br />
            <br />
            5.2.2&nbsp; We do not warrant that Kenwyn Accounting and IT Services
            Pte Ltd will meet your requirements or that the operation of Kenwyn
            Accounting and IT Services Pte Ltd will be uninterrupted, error-free
            or completely secure. Further, we do not warrant that all errors in
            Kenwyn Accounting and IT Services Pte Ltd can or will be corrected.

            <br />
            <br />
            5.2.3&nbsp;You acknowledge that there are risks inherent in the
            connectivity involving the Internet that could result in the loss of
            your privacy and Client Data. We will not be responsible for any
            loss of or change in Client Data or inability to perform certain
            tasks resulting from your decision to downgrade your Subscription.
          </p>

          <br />
          <br />
          <h4>6.&nbsp;LIMITATION OF LIABILITY</h4>
          <br />
          <p>
            6.1 &nbsp;To the fullest extent permitted by applicable law, in no
            event will Kenwyn Accounting and IT Services Pte Ltd, its
            affiliates, officers, employees, agents, suppliers or licensors be
            liable for any indirect, special, incidental, exemplary or
            consequential damages or for damages relating to telecommunication
            failures, loss, corruption, security or theft of in contract, tort
            (including negligence), product liability or otherwise, whether or
            not Kenwyn Accounting and IT Services Pte Ltd has been warned of the
            possibility of such damages, and even if a remedy fails of its
            essential purpose.
          </p>
          <br />
          <br />
          <h4>7.&nbsp;INDEMNIFICATION</h4>
          <br />
          <p>
            7.1&nbsp; To the fullest extent allowed by applicable law, you agree
            to indemnify and hold Kenwyn Accounting and IT Services Pte Ltd, its
            affiliates, officers, agents, employees, suppliers, licensors and
            partners harmless from and against any and all claims, liabilities,
            damages (“Claim”) (actual and consequential), losses and expenses
            (including reasonable attorneys’ fees) arising from or in any way
            related to any third party claims that result from:
            <br />
            <br />
            1.&nbsp;Your breach of any provision of this Agreement;<br />
            2.&nbsp;Your use of Kenwyn Accounting and IT Services Pte Ltd;<br />
            3.&nbsp;Negligent or wilful misconduct of your employees,
            contractors, or agents; or<br />4 Contractual or other relationships
            between you and your customers.
            <br />
            <br />
            7.2&nbsp; In the event of such a Claim, we will attempt to provide
            notice of the Claim to the contact information we have for your
            Account (provided that failure to deliver such notice shall not
            eliminate or reduce your indemnification obligations hereunder).
          </p>

          <br />
          <br />
          <h4>8.&nbsp;CONTENT</h4>
          <br />
          <p>
            8.1 &nbsp;In using Kenwyn Accounting and IT Services Pte Ltd, you
            provide us with information, files, and folders that you submit to
            us (together, “Client Data”). You, and not us, are responsible for
            maintaining and protecting all of your Client Data. We will not be
            liable for any loss or corruption of your Client Data, or for any
            costs or expenses associated with backing up or restoring any of
            your Client Data.
            <br />
            <br />
            8.2 &nbsp;You retain full ownership of your Client Data, but you
            hereby grant us a worldwide, non-exclusive, irrevocable,
            transferable, perpetual, royalty-free license (with the right to
            sublicense) to use, copy, reproduce, process, adapt, modify,
            publish, transmit, edit, translate, make derivatives, display and
            distribute any and all Client Data in connection with providing
            Kenwyn Accounting and IT Services Pte Ltd to you and other users.
            <br />
            <br />
            8.3 &nbsp;This license also includes the right to modify or adapt
            your Client Data in order to transmit, display or distribute it over
            computer networks and in various media and/or make changes to your
            Client Data to conform and adapt that Client Data to any
            requirements or limitations of any networks, devices, services or
            media. You also extend these rights to the third party service
            providers with whom we work to provide Kenwyn Accounting and IT
            Services Pte Ltd.
            <br />
            <br />
            8.4 &nbsp;All Client Data in or on Kenwyn Accounting and IT Services
            Pte Ltd, whether publicly posted or privately transmitted by users,
            is the sole responsibility of the person who originated such Client
            Data. We do not endorse, support, represent or guarantee the
            completeness, truthfulness, accuracy, or reliability of any Client
            Data or communications posted or endorse any opinions expressed. If
            you use or rely on any Client Data or materials posted via Kenwyn
            Accounting and IT Services Pte Ltdor obtained by you through Kenwyn
            Accounting and IT Services Pte Ltd, it is at your own risk. Under no
            circumstances will we be liable in any way for any Client Data, or
            any loss or damage of any kind incurred as a result of the use of
            any Client Data in Kenwyn Accounting and IT Services Pte Ltd.
            <br />
            <br />
            8.5 &nbsp;We may use your name and logo on our website for the
            purpose of marketing Kenwyn Accounting and IT Services Pte Ltd.
          </p>

          <br />
          <br />

          <h4>9. &nbsp;THIRD PARTY LINKS</h4>
          <br />
          <p>
            9.1&nbsp; We may reference or provide access to third-party
            services, products, and promotions that utilise, integrate, or
            provide ancillary services to Kenwyn Accounting and IT Services Pte
            Ltd(“Third-Party Links”). These Third-Party Links are provided for
            your convenience only and do not constitute our approval,
            endorsement, or recommendation of any such Third-Party Links for
            you.
            <br />
            <br />
            9.2 &nbsp;Your access and usage of any Third-Party Links will be
            based on your own evaluation and at your own risk. You understand
            that your use of any Third-Party Links is not governed by this
            Agreement. If you decide to use a Third-Party Link, you will be
            responsible for reviewing, understanding and accepting the terms and
            conditions associated with its use.
            <br />
            <br />
            9.3 &nbsp;We expressly disclaim all responsibility and liability for
            your use of any Third-Party Links. Please also remember that when
            you use a Third-Party Links, our Privacy Policy is no longer in
            effect. Your use of a Third-Party Link, including those that have a
            link on Kenwyn Accounting and IT Services Pte Ltd, is subject to
            that Third-Party Link’s own terms of use and privacy policies.
          </p>

          <br />
          <br />
          <h4>10.&nbsp; GENERAL PROVISIONS</h4>
          <br />
          <p>
            <b>10.1&nbsp; Entire Agreement and Severability</b>
            <br />
            <br />
            10.1.1 &nbsp;These Terms of Service will constitute the entire
            agreement between Kenwyn Accounting and IT Services Pte Ltd and you
            with respect to the subject matter contemplated herein, and
            supersede all oral statements and prior writings with respect to the
            subject matter contemplated herein.

            <br />
            <br />
            10.1.2&nbsp; If any provision of these Terms of Service shall be
            adjudged by any court of competent jurisdiction to be unenforceable
            or invalid, that provision shall be limited to the minimum extent
            necessary so that these Terms of service shall otherwise remain in
            effect. No waiver shall be implied from conduct or failure to
            enforce or exercise rights under these Terms of Service. Nor will
            any waiver be effective unless in writing signed by a duly
            authorized representative on behalf of the party claiming to have
            waived.
            <br />
            <br />
            10.1.3 &nbsp;This Agreement represents the complete and exclusive
            understanding relating to the Agreement’s subject matter. It
            supersedes all prior or contemporaneous oral or written
            communications, proposals and representations with respect to the
            subject matter covered by this Agreement.
          </p>
          <br />
          <br />
          <p>
            <b>10.2&nbsp; Notices</b>
            <br />
            <br />
            10.2.1&nbsp; We may provide notifications electronically, whether
            these are required by law or are for marketing or other business
            related purposes, to you via email, or through posting of such
            notice on our website, as determined by us in our sole discretion.

            <br />
            <br />
            10.2.2 &nbsp;You agree that all agreements, notices, disclosures and
            other communications that we provide to you electronically satisfy
            any legal requirement that such communication be in writing.
          </p>

          <br />
          <br />
          <p>
            <b>10.3&nbsp; Survival</b>
            <br />
            <br />
            10.3.1&nbsp; All Clauses of the Agreement which by their nature
            should survive termination of any agreement between you and us, will
            survive termination, including, without limitation, restrictions,
            accrued rights to payment, confidentiality obligations, intellectual
            property rights, warranty disclaimers, and limitations of liability.
          </p>

          <br />
          <br />
          <p>
            <b> 10.4 &nbsp;Assignment</b>
            <br />
            <br />
            10.4.1&nbsp; The Agreement and any rights and licenses granted
            hereunder, may not be transferred or assigned by you without our
            written permission, but may be assigned by us without restriction.
            Any attempted transfer or assignment by you will be null and void.
          </p>

          <br />
          <br />
          <p>
            <b>10.5&nbsp; Data Protection</b>
            <br />
            <br />
            10.5.1&nbsp; Kenwyn Accounting and IT Services Pte Ltd and user
            agree to comply with our respective obligations under the Personal
            Data Protection Act (No. 26 of 2012) as applicable to personal data
            that it controls or processes as part of, or in connection with, its
            use or provision of Kenwyn Accounting and IT Services Pte Ltd.
            <br />
            <br />
            10.5.2&nbsp; Specifically, but without limitation, you must comply
            with the Act as it relates to personal data that you store or
            transfer using your system.
          </p>

          <br />
          <br />
          <p>
            <b>10.6 &nbsp;Force Majeure</b>
            <br />
            <br />
            10.6.1 &nbsp;Neither we nor you will be in breach of the Agreement
            if the failure to perform the obligation is caused by a condition
            that was beyond the party’s reasonable control, such as, natural
            disaster or significant Internet disturbance, or other events of a
            type for which precautions are not generally taken in the industry.
          </p>

          <br />
          <br />
          <p>
            <b>10.7 &nbsp;Relationship</b>
            <br />
            <br />
            10.7.1&nbsp; Nothing in this Agreement serves to establish a
            partnership, joint venture, or other agency relationship between you
            and us. The relationship between the parties is that of independent
            contractors.
          </p>

          <br />
          <br />
          <p>
            <b> 10.8 &nbsp;Governing Law</b>
            <br />
            <br />
            10.8.1 &nbsp;The Agreement will be governed and construed in
            accordance with the substantive laws of Singapore as applied to
            agreements entered into and to be performed entirely within
            Singapore, without regard to its conflicts of law principles that
            would require application of law of a different jurisdiction.
          </p>

          <br />
          <br />
          <p>
            <b> 10.9&nbsp; Dispute Resolution</b>
            <br />
            <br />
            10.9.1 &nbsp;All disputes, controversies or differences (“Dispute”)
            arising out of or in connection with this Agreement, including any
            question regarding its existence, validity or termination, shall be
            referred to and finally resolved by arbitration in Singapore in
            accordance with the Arbitration Rules of the Singapore International
            Arbitration Centre (“SIAC”) for the time being in force.
            <br />
            <br />
            10.9.2&nbsp; The language of the arbitration shall be English.
            <br />
            <br />
            10.9.3 &nbsp;The award rendered by the arbitrator shall include
            costs of arbitration, reasonable attorneys’ fees and reasonable
            costs for expert and other witnesses. The prevailing party will be
            entitled to recover its attorneys’ fees and costs in connection with
            such an action.
          </p>

          <br />
          <br />
          <p>
            <b>10.10 &nbsp;Contact Details</b>
            <br />
            <br />
            10.10.1 &nbsp;If you have any enquiries, concerns or comments that
            relate to our Agreement, please contact us at
            info@kenwynaccounting.com.
          </p>

          <br />
          <br />
          <h4>11. &nbsp;DEFINITIONS</h4>
          <br />
          <p>
            1.&nbsp;“Affiliate” means a subsidiary or holding company of Kenwyn
            Accounting and IT Services Pte Ltd either party to this Agreement
            and any subsidiary of such holding company (where “holding company”
            and “subsidiary” have the meanings set out in the Singaporean
            Companies Act).<br />
            2.&nbsp;“Client Data” means all information, data and other content
            provided by Customer in connection with its authorized use of the
            Service, including, without limitation, all data and information
            transmitted to the Service.<br />
            3.&nbsp;“Documentation” means the then-current technical and
            functional information regarding the Services that is provided by
            Kenwyn Accounting and IT Services Pte Ltd to users in electronic or
            other form.<br />
            4.&nbsp;“Fees” means the subscription fees and any other amounts due
            to Kenwyn Accounting and IT Services Pte Ltd and payable by you
            under this Agreement.<br />
            5.&nbsp;“Order Form” means an order form referencing this Agreement
            that has been mutually agreed to and executed by the parties.<br />
            6.&nbsp;“Services” means the professional services provided to you
            by Kenwyn Accounting and IT Services Pte Ltd pursuant to this
            Agreement.<br />
            7.&nbsp;“Term” means the period of effectiveness of this Agreement
            as specified in Clause 4.1 above.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

.accounding {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}
.rowColor {
  background-color: green;
  color: white;
}
.head {
  font-size: 50px;
  padding-top: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}
</style>
