<template>
  <!--base image-->

  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>

        <img class="first-slide img-fluid" src="@/assets/book5.jpg" />

        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>Kenwyn Books</b>
          </h3>

          <p class="text-left h6 text-justify paraHead pt-3">
            Empowering Seamless Connectivity and Efficiency
          </p>

          <div class="pt-2 text-left">
            <button class="buttonService">
              <b>
                <a href="/features" class="pl-2 contactUs" to="/features">
                  FEATURES
                </a>
              </b>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-text pl-5">
        <h3 class="text-left h1 text-light text-head pt-5">
          <b>Kenwyn Books</b>
        </h3>
        <p class="text-left h6 text-justify paraHead pt-3">
          Empowering Seamless Connectivity and Efficiency
        </p>
        <div class="pt-2 text-left">
          <button class="buttonService">
            <b
              ><a href="/features" class="pl-2 contactUs" to="/features"
                >FEATURES
              </a></b
            >
          </button>
        </div>
      </div> -->
  </div>

  <!--base image-->

  <div class="container-fluid">
    <h1 class="pt-3 text-center"><b>KENWYN BOOKS</b></h1>

    <!-- <h2>ACCOMPLISH MORE, PERFORM BETTER, SAVE TIME</h2>-->

    <div class="row pt-5">
      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 53rem">
            <br />

            <h4 class="card-title"><b>Web-Based Accounting</b></h4>

            <h6 ALIGN="CENTER" class="sub-card-title">
              Manage your Company Accounts
            </h6>

            <img
              class="card-img-top pt-4 pl-2 pr-2"
              src="@/assets/accounting.jpg"
              alt="Card image cap"
            />

            <div class="card-body">
              <h6 ALIGN="CENTER"><b>Features</b></h6>

              <ul class="pt-3 featureContent">
                <li>- Quotations and Invoices</li>

                <li>- Sales & Purchase Orders</li>

                <li>- Credit Notes</li>

                <li>- Inventory Management</li>

                <li>- Financial Reporting</li>
              </ul>
            </div>

            <div class="text-center patp">
              <a
                href="/features"
                ALIGN="CENTER"
                class="btn btn-outline-success"
              >
                <b>EXPLORE MORE</b>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 53rem">
            <br />

            <h4 class="card-title"><b>Payroll</b></h4>

            <h6 ALIGN="CENTER" class="sub-card-title">Manpower Management</h6>

            <img
              class="card-img-top pt-4 pl-2 pr-2"
              src="@/assets/pay.jpg"
              alt="Card image cap"
            />

            <div class="card-body">
              <h6 ALIGN="CENTER"><b>Features</b></h6>

              <ul class="pt-3 featureContent">
                <li>- Leave Management</li>

                <li>- Employee Claims Approval</li>

                <li>- Itemised Payslips</li>

                <li>- CPF Computation</li>

                <li>- Attendance Management</li>

                <li>- Employee Particulars e-Tracking</li>
              </ul>
            </div>

            <div class="text-center patp1">
              <a
                href="/features"
                ALIGN="CENTER"
                class="btn btn-outline-success"
              >
                <b>EXPLORE MORE</b>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-sm-12 col-lg-4">
        <div class="inner">
          <div class="card_one shadow" style="height: 53rem">
            <br />

            <h4 class="card-title"><b>Corporate Secretary</b></h4>

            <h6 ALIGN="CENTER" class="sub-card-title">
              Registered Filing Agent
            </h6>

            <img
              class="card-img-top pt-4 pl-2 pr-2"
              src="@/assets/sample2.jpg"
              style="height: 230px"
              alt="Card image cap"
            />

            <div class="card-body">
              <h6 ALIGN="CENTER"><b>Features</b></h6>

              <ul class="pt-3 featureContent">
                <li>- Preparation of secretarial documents</li>

                <li>- Management of compliance datelines</li>
              </ul>
            </div>

            <div class="">
              <div class="text-center patp2">
                <a
                  href="/features"
                  ALIGN="CENTER"
                  class="btn btn-outline-success detailBtn2"
                >
                  <b>EXPLORE MORE</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <!--color page-->

    <!-- <div class="row pt-5 pb-5 pr-5 rowColor">
      <div class="col-md-6 col-sm-12 col-lg-8 rowClr">
        <div>
          <h5 class="conQus"><b>HAVE QUESTIONS? LET US KNOW!</b></h5>

          <p class="pl-2 text-justify">
            Have questions on your accounting, corporate secretarial and
            corporate and income tax matters? Book and receive a free
            consultation session with us today! Let us hear you and be your
            solutions in every of your accounting, corporate secretarial and
            income tax matters.
          </p>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col-lg-4 pl-5 rowClr">
        <div class="pt-5">
          <button type="button" class="btn btn-outline-light">
            CONTACT US
          </button>
        </div>
      </div>
    </div> -->

    <!--color page-->

    <br />

    <div class="row pt-5 pb-5 pr-5 rowColorTwo">
      <div class="col-md-12 col-sm-12 col-lg-12 rowClr">
        <div>
          <h6 class="conQusTwo pt-5"><b>SOCIAL MEDIA</b></h6>

          <div class="socialMedia">
            <span class="pl-2 socialMediah">
              We're on social media and we'd love you to give us a follow !
              <br />
              Stay connected with Kenwyn Accounting and IT Services. Choose your
              favourite social media channel.
            </span>

            <div class="pt-5 buttonFolow">
              <a
                type="button"
                class="btn btn-light"
                href="https://www.facebook.com/kenwynaccountinganditservices/"
                target="_blank"
              >
                <b>
                  FOLLOW US &nbsp;
                  <i class="fa fa-facebook-square" style="font-size: 20px"></i>
                </b>
              </a>

              <br />

              <br />

              <a
                type="button"
                class="btn btn-light"
                href="https://www.linkedin.com/company/kenwynaccounting"
                target="_blank"
              >
                <b>FOLLOW US</b>
                &nbsp;
                <i class="fa fa-linkedin-square" style="font-size: 20px"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--contact us page-->

    <div class="row pt-5 pl-4">
      <div class="col-md-6 col-sm-12 col-lg-6">
        <h5><b>KEEP IN TOUCH WITH US</b></h5>

        <div class="text-secondary text-justify">
          <p class="pt-3 pl-2">
            Interested in Kenwyn Books? Speak to our sales team.
          </p>
        </div>

        <div class="pl-5 text-secondary">
          <p class="pt-3">
            <i
              class="fa fa-mobile fa-1x"
              aria-hidden="true"
              style="font-size: 30px"
            >
              &nbsp;&nbsp;
            </i>

            <span>+65-97223997</span>
          </p>

          <hr />

          <p class="pt-3">
            <i
              class="fa fa-envelope fa-1x"
              aria-hidden="true"
              style="font-size: 30px"
            >
              &nbsp;&nbsp;
            </i>

            <span>info@kenwynaccounting.com</span>
          </p>
        </div>
      </div>

      <div class="col-md-6 col-sm-12 col-lg-6 pt- 3 pr-5">
        <h5 class="Link"><b>SEND US YOUR MESSAGE</b></h5>

        <div class="pl-2 text-secondary">
          <div class="mb-3 pt-3">
            <label for="exampleFormControlInput1" class="form-label">
              Name
            </label>

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Email
            </label>

            <input
              type="email"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Subject
            </label>

            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
            />
          </div>

          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label">
              Message
            </label>

            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>

          <div class="pt-4">
            <a
              type="button"
              class="btn btn-outline-secondary"
              href="mailto:info@kenwynaccounting.com?subject=Accounting%20Services%20Required"
            >
              <b> SEND MESSAGE</b>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <h4 class="tecPart"><b>TECHNOLOGY PARTNERS</b></h4>

      <div class="row pt-4 pl-5">
        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-1.a7858c2b.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-2.5388542b.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-3.10f11173.png"
          />
        </div>

        <div class="col-md-3 col-sm-6 col-lg-3">
          <img
            class="pl-5 pt-4 text-center"
            style="width: 200px"
            src="https://lk.kenwynconsultancy.com/img/Technology%20Partner-4.245a1d82.png"
          />
        </div>
      </div>
    </div>

    <br />

    <div class="m-3">
      <div
        class="row p-3 rowColor d-flex align-items-center justify-content-center"
      >
        <div class="col-md-6 col-sm-12 col-lg-6 align-middle">
          <h3 class="text-dark pt-3 pl-2">
            Exceeding Standards: Unmatched Value and Personalized Benefits with
            Our Unique Software
          </h3>

          <p class="text-secondary pt-3 pl-2">
            Our software goes beyond industry standards, offering a unique array
            of features that deliver exceptional value and personalized benefits
            for you.
          </p>
        </div>

        <div class="col-md-6 col-sm-12 col-lg-6 pr-5">
          <div class="row text-dark">
            <div class="col-md-6 col-sm-12 col-lg-6 text-center">
              <div class="card">
                <div class="card-body">
                  <i
                    class="fa fa-user-plus fa-2x"
                    aria-hidden="true"
                    style="color: #028237"
                  ></i>

                  <p><b>Unlimited Company /Users Creation</b></p>

                  <p class="hadcolor">
                    Offering unmatched flexibility and scalability to cater to
                    your diverse data management requirements.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-lg-6 text-center">
              <div class="card">
                <div class="card-body">
                  <i
                    class="fa fa-server fa-2x"
                    aria-hidden="true"
                    style="color: #028237"
                  ></i>
                  <p>
                    <b>
                      Multiple Deployment Options: On-Premise, Cloud, or Hybrid
                    </b>
                  </p>

                  <p class="hadcolor">
                    Empower your business with the flexibility to choose the
                    deployment model that fits your needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-lg-6 text-center">
              <div class="card">
                <div class="card-body">
                  <i
                    class="fa fa-usd fa-2x"
                    aria-hidden="true"
                    style="color: #028237"
                  ></i>
                  <p><b>Multi-Currency Support</b></p>

                  <p class="hadcolor">
                    Easily handle transactions in various currencies with our
                    software's powerful multi-currency feature.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-lg-6 text-center">
              <div class="card">
                <div class="card-body">
                  <i
                    class="fa fa-cogs fa-2x"
                    aria-hidden="true"
                    style="color: #028237"
                  ></i>
                  <p><b>API Integration with Third-Party Solutions</b></p>

                  <p class="hadcolor">
                    Seamlessly integrate and utilize external tools to enhance
                    and streamline your business processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
    </div>
  </div>

  <!--contact us page-->
</template>

<script>
export default { name: "Home", components: {} };
</script>

<!--style css start-->

<style scoped>
h5,
h6 {
  margin: 5px;
  padding: 5px;
  color: #040404;
}
h4 {
  text-align: center;
  color: #000000;
  padding-top: 20px;
}
h1,
h2 {
  text-align: center;
  color: #099118;
  letter-spacing: 0.1px;
}
.socialIcon {
  text-align: center;
}
.socialMedia {
  color: rgba(234, 229, 229, 0.551);
  text-align: center;
  letter-spacing: 1px;
  font-size: 14px;
  padding-top: 30px;
}

.socialMediah {
  color: rgb(255, 255, 255);
  text-align: center;
  letter-spacing: 3px;
  padding-top: 30px;
  font-size: 20px;
}
.card-img-top {
  max-width: 350px;
}
.card-title {
  color: #099118;
}
.container_card {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 40px 40px 40px;
  margin: 5px;
  background-color: #f8f8f8a1;
}
.rowColor {
  background-color: #099118;
}
.rowColorTwo {
  background-color: #096514;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.inner {
  padding: 10px;
  transition: 0.5s ease-in-out;
}
.inner:hover {
  transform: scale(1.1);
}
ul {
  text-align: left;
  margin: 0.5;
}

li {
  list-style: none;
  margin-bottom: 15px;
}
.container_image {
  background: #099118;
}
.carousel-item {
  height: 32rem;
  background: #fff;
  color: darkblue;
  position: relative;
  padding-top: 100px;
  border-radius: 0;
}
.container_text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 30px;
}
.slide_text {
  background: #099118;
}
.container_about_dash {
  background: rgb(218, 216, 216);
}
.column {
  float: left;
  width: 10%;
  padding: 5px;
  vertical-align: middle;
  text-align: center;
  align-items: center;
}
.row_logo::after {
  content: "";
  clear: both;
  display: table;
}
.text-head {
  font-size: 55px;
}
.column_com_1 {
  box-sizing: border-box;
  background: #0f206c;
  width: 100%;
  display: inline-block;
  border-radius: 0;
  padding: 25px 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-position: 98% 100%;
  background-size: 33%;
  color: #fff;
}
.conQus {
  color: white;
}
.conQusTwo {
  color: white;
  font-size: 30px;
  text-align: center;
}
.rowClr {
  color: white;
}
.head {
  font-size: 60px;
  color: white;
  text-align: center;
}
.column_com_2 {
  padding: 25px 10px 25px 10px;
  background-color: #f1f0f6;
  border-radius: 0px 10px 10px 00px;
  background-position: 98% 100%;
  background-size: 33%;
}

.left_side {
  background: red;
}
.right_side {
  background: black;
}
.trial {
  color: #fff;
  cursor: pointer;
  background-color: transparent;
}
.card:hover {
  background: rgb(247, 244, 244);
}
.card {
  box-sizing: border-box;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  background-position: 98% 100%;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.card_one {
  margin-bottom: 12px;
}
.card_two {
  box-sizing: content-box;
  border: solid rgb(223, 217, 217);
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  margin-right: 10px;
  margin-left: 15px;
  margin-bottom: 12px;
  background-position: 98% 100%;
}
.card_slide {
  border: 2px solid #f1effa;
  border-radius: 20px !important;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.head {
  font-size: 60px;
  color: white;
  text-align: center;
}

/* .img-gradient {
  position: relative;
  display: inline-block;
}

.img-overlay {
  background-color: black;
  width: 100%;
  height: 200px;
}

.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
} */

.img-container {
  max-width: 100%;
  position: relative;
  /* display: inline-block; */
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

.patp {
  margin: 80px;
}

.patp1 {
  margin: 40px;
}

.patp2 {
  margin-top: 225px;
}

.rowColor {
  background-color: #f9fafb;
}

.hadcolor {
  color: #028237;
}

@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .text-head {
    font-size: 70px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 100%;
  }
  .text-head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .text-head {
    font-size: 30px;
  }
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
/* .img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  ); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 );
} */
/* .img-gradient img {
  display: block;
} */
.buttonService {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 12px 28px;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.contactUs {
  text-decoration: none;
  color: white;
}
.paraHead {
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
  font-size: 14px;
}
</style>
