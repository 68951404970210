<template>
  <div><h1>Our Partners</h1></div>
</template>

<style scoped>
h1 {
  padding-top: 150px;
  color: green;
}
</style>
