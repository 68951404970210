<template>
  <!--abou image-->
  <!-- <div class="img-gradient">
    <img class="first-slide" src="@/assets/bok.jpg" />
  </div>
  <div class="bg-text">
    <h2 class="head"><b>REGISTER</b></h2>
    <p class="pt-3 aboutPara"></p>
  </div> -->

  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/book4.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>REGISTER</b>
          </h3>
        </div>
      </div>
    </div>
  </div>

  <!--abou image-->

  <div class="container  py-5 h-100">
    <div class="card_one shadow">
      <div class="card-body p-5">
        <div class="row">
          <h2 ALIGN="CENTER" class="card-title mb-4">Sign Up</h2>
          <div class="col-md-4">
            <img
              class="card-img-top pt-4 pl-2 pr-2"
              src="@/assets/accounting.jpg"
              alt="Card image cap"
            />
          </div>
          <div class="col-md-4">
            <h6 class="sub-card-title">
              USER DETAILS
            </h6>

            <div class="form-group mb-1">
              <label for="inputFullName">Full Name</label>
              <span class="text-danger">*</span>
              <input
                type="text"
                class="form-control"
                id="inputFullName"
                placeholder=""
                required
                v-model="fullname"
              />
            </div>

            <!-- <div class="form-group">
              <label for="inputPhoneNo">Phone No.</label>
              <span class="text-danger">*</span>
              <input
                type="number"
                class="form-control"
                id="inputPhoneNo"
                required
                v-model="phoneno"
              />
            </div> -->

            <label for="inputPhoneNo">Phone No.</label>
            <span class="text-danger">*</span>
            <div class="form-group mb-1 phon1">
              <span class="border-end country-code px-2">+65</span>
              <input
                type="phone"
                class="form-control "
                id="inputPhoneNo"
                required
                v-model="phoneno"
                placeholder="91257888"
              />
            </div>

            <div class="form-group mb-1">
              <label for="inputBusinessName">Business Name</label>
              <span class="text-danger">*</span>
              <input
                type="text"
                class="form-control"
                id="inputBusinessName"
                placeholder=""
                required
                v-model="businessname"
              />
            </div>

            <div class="form-group mb-1">
              <label for="usertype">User Type</label>
              <span class="text-danger">*</span>
              <select
                class="form-select"
                name=""
                id="usertype"
                required
                v-model="usertype"
              >
                <option value=""></option>
                <option value="Single entity / Individual"
                  >Single entity / Individual</option
                >
                <option value="Multiple entities">Multiple entities</option>
              </select>
            </div>

            <div class="form-group mb-1">
              <label for="inputemail">Email</label>
              <span class="text-danger">*</span>
              <input
                type="email"
                class="form-control"
                id="inputemail"
                placeholder=""
                required
                v-model="email"
              />
            </div>

            <div class="form-group">
              <label for="inputpassword">Password</label>
              <span class="text-danger">*</span>
              <input
                type="password"
                class="form-control"
                id="inputPassword"
                placeholder=""
                required
                v-model="password"
              />
            </div>
          </div>
          <div class="col-md-4">
            <h6 class="sub-card-title">
              BUSINESS DETAILS
            </h6>

            <div class="form-group mb-1">
              <label for="country">Country</label>
              <span class="text-danger">*</span>
              <select
                class="form-select"
                name=""
                id="country"
                required
                v-model="country"
              >
                <option value=""></option>
                <option value="Singapore">Singapore</option>
              </select>
            </div>

            <div class="form-group mb-1">
              <label for="industry">Industry</label>
              <span class="text-danger">*</span>
              <select
                class="form-select"
                name=""
                id="industry"
                required
                v-model="industry"
              >
                <option value=""></option>
                <optgroup
                  label="Agriculture, Forestry, Fishing"
                  data-subtext="optgroup subtext"
                >
                  <option value="Agricultural Production - Crops"
                    >Agricultural Production - Crops</option
                  >
                  <option
                    value="Agricultural Production - Livestock and Animal Specialities"
                    >Agricultural Production - Livestock and Animal
                    Specialities</option
                  >
                  <option value="Agricultural Services"
                    >Agricultural Services</option
                  >
                  <option value="Forestry">Forestry</option>
                  <option value="Fishing, Hunting and Trapping"
                    >Fishing, Hunting and Trapping</option
                  >
                </optgroup>

                <optgroup label="Construction" data-subtext="optgroup subtext">
                  <option
                    value="Construction - General Contractors & Operative Builders"
                    >Construction - General Contractors & Operative
                    Builders</option
                  >
                  <option
                    value="Heavy Construction, Except Building Construction, Contractor"
                    >Heavy Construction, Except Building Construction,
                    Contractor</option
                  >
                  <option value="Construction - Special Trade Contractors"
                    >Construction - Special Trade Contractors</option
                  >
                </optgroup>

                <optgroup
                  label="Finance, Insurance, Real Estate"
                  data-subtext="optgroup subtext"
                >
                  <option value="Depository Institutions"
                    >Depository Institutions</option
                  >
                  <option value="Non-depository Credit Institutions"
                    >Non-depository Credit Institutions</option
                  >
                  <option
                    value="Security & Commodity Brokers, Dealers, Exchanges & Servi..."
                    >Security & Commodity Brokers, Dealers, Exchanges &
                    Servi...</option
                  >
                  <option value="Insurance Carriers">Insurance Carriers</option>
                  <option value="Insurance Agents, Brokers and Service"
                    >Insurance Agents, Brokers and Service</option
                  >
                  <option value="Real Estate">Real Estate</option>
                  <option value="Holding And Other Investment Offices"
                    >Holding And Other Investment Offices</option
                  >
                </optgroup>

                <optgroup label="Manufacturing" data-subtext="optgroup subtext">
                  <option value="Food and Kindred Products"
                    >Food and Kindred Products</option
                  >
                  <option value="Tobacco Products">Tobacco Products</option>
                  <option value="Textile Mill Products"
                    >Textile Mill Products</option
                  >
                  <option
                    value="Apparel, Finished Products from Fabrics & Similar Materials"
                    >Apparel, Finished Products from Fabrics & Similar
                    Materials</option
                  >
                  <option value="Lumber And Wood Products, Except Furniture"
                    >Lumber And Wood Products, Except Furniture</option
                  >
                  <option value="Furniture And Fixtures"
                    >Furniture And Fixtures</option
                  >
                  <option value="Paper And Allied Products"
                    >Paper And Allied Products</option
                  >
                  <option value="Printing, Publishing and Allied Industries"
                    >Printing, Publishing and Allied Industries</option
                  >
                  <option value="Chemicals And Allied Products"
                    >Chemicals And Allied Products</option
                  >
                  <option value="Petroleum Refining and Related Industries"
                    >Petroleum Refining and Related Industries</option
                  >
                  <option value="Rubber And Miscellaneous Plastic Products"
                    >Rubber And Miscellaneous Plastic Products</option
                  >
                  <option value="Leather And Leather Products"
                    >Leather And Leather Products</option
                  >
                  <option value="Stone, Clay, Glass and Concrete Products"
                    >Stone, Clay, Glass and Concrete Products</option
                  >
                  <option value="Primary Metal Industries"
                    >Primary Metal Industries</option
                  >
                  <option value="Fabricated Metal Products"
                    >Fabricated Metal Products</option
                  >
                  <option
                    value="Industrial and Commercial Machinery and Computer Equip..."
                    >Industrial and Commercial Machinery and Computer
                    Equip...</option
                  >
                  <option
                    value="Electronic & Other Electrical Equipment & Components"
                    >Electronic & Other Electrical Equipment &
                    Components</option
                  >
                  <option value="Transportation Equipment"
                    >Transportation Equipment</option
                  >
                  <option
                    value="Measuring, Photographic, Medical, Optical Goods, & Clocks"
                    >Measuring, Photographic, Medical, Optical Goods, &
                    Clocks</option
                  >
                  <option value="Miscellaneous Manufacturing Industries"
                    >Miscellaneous Manufacturing Industries</option
                  >
                </optgroup>

                <optgroup label="Mining" data-subtext="optgroup subtext">
                  <option value="Metal Mining">Metal Mining</option>
                  <option value="Coal Mining">Coal Mining</option>
                  <option value="Oil And Gas Extraction"
                    >Oil And Gas Extraction</option
                  >
                  <option
                    value="Mining And Quarrying of Non-metallic Minerals, Except Fuels"
                    >Mining And Quarrying of Non-metallic Minerals, Except
                    Fuels</option
                  >
                </optgroup>

                <optgroup
                  label="Nonclassifiable Establishments"
                  data-subtext="optgroup subtext"
                >
                  <option value="Non-classifiable Establishments"
                    >Non-classifiable Establishments</option
                  >
                </optgroup>

                <optgroup
                  label="Public Administration"
                  data-subtext="optgroup subtext"
                >
                  <option
                    value="Executive, Legislative & General Government, Except Finance"
                    >Executive, Legislative & General Government, Except
                    Finance</option
                  >
                  <option value="Justice, Public Order and Safety"
                    >Justice, Public Order and Safety</option
                  >
                  <option value="Public Finance, Taxation and Monetary Policy"
                    >Public Finance, Taxation and Monetary Policy</option
                  >
                  <option value="Administration Of Human Resource Programs"
                    >Administration Of Human Resource Programs</option
                  >
                  <option
                    value="Administration Of Environmental Quality and Housing Prog..."
                    >Administration Of Environmental Quality and Housing
                    Prog...</option
                  >
                  <option value="Administration Of Economic Programs"
                    >Administration Of Economic Programs</option
                  >
                  <option value="National Security and International Affairs"
                    >National Security and International Affairs</option
                  >
                </optgroup>

                <optgroup label="Retail Trade" data-subtext="optgroup subtext">
                  <option
                    value="Building Materials, Hardware, Garden Supplies & Mobile H..."
                    >Building Materials, Hardware, Garden Supplies & Mobile
                    H...</option
                  >
                  <option value="General Merchandise Stores"
                    >General Merchandise Stores</option
                  >
                  <option value="Food Stores">Food Stores</option>
                  <option
                    value="Automotive Dealers and Gasoline Service Stations"
                    >Automotive Dealers and Gasoline Service Stations</option
                  >
                  <option value="Apparel And Accessory Stores"
                    >Apparel And Accessory Stores</option
                  >
                  <option
                    value="Home Furniture, Furnishings and Equipment Stores"
                    >Home Furniture, Furnishings and Equipment Stores</option
                  >
                  <option value="Eating And Drinking Places"
                    >Eating And Drinking Places</option
                  >
                  <option value="Miscellaneous Retails"
                    >Miscellaneous Retails</option
                  >
                </optgroup>

                <optgroup label="Services" data-subtext="optgroup subtext">
                  <option
                    value="Hotels, Rooming Houses, Camps, And Other Lodging Places"
                    >Hotels, Rooming Houses, Camps, And Other Lodging
                    Places</option
                  >
                  <option value="Personal Services">Personal Services</option>
                  <option value="Business Services">Business Services</option>
                  <option value="Automotive Repair, Services and Parking"
                    >Automotive Repair, Services and Parking</option
                  >
                  <option value="Miscellaneous Repair Services"
                    >Miscellaneous Repair Services</option
                  >
                  <option value="Motion Pictures">Motion Pictures</option>
                  <option value="Amusement And Recreation Services"
                    >Amusement And Recreation Services</option
                  >
                  <option value="Health Services">Health Services</option>
                  <option value="Legal Services">Legal Services</option>
                  <option value="Educational Services"
                    >Educational Services</option
                  >
                  <option value="Social Services">Social Services</option>
                  <option
                    value="Museums, Art Galleries and Botanical and Zoological Garde.."
                    >Museums, Art Galleries and Botanical and Zoological
                    Garde..</option
                  >
                  <option value="Membership Organizations"
                    >Membership Organizations</option
                  >
                  <option
                    value="Engineering, Accounting, Research, And Management Servi..."
                    >Engineering, Accounting, Research, And Management
                    Servi...</option
                  >
                  <option value="Private Households">Private Households</option>
                  <option value="Services, Not Elsewhere Classified"
                    >Services, Not Elsewhere Classified</option
                  >
                </optgroup>

                <optgroup
                  label="Transportation & Public Utilities"
                  data-subtext="optgroup subtext"
                >
                  <option value="Railroad Transportation"
                    >Railroad Transportation</option
                  >
                  <option
                    value="Local & Suburban Transit & Interurban Highway Transporta.."
                    >Local & Suburban Transit & Interurban Highway
                    Transporta..</option
                  >
                  <option value="Motor Freight Transportationsg"
                    >Motor Freight Transportation</option
                  >
                  <option value="United States Postal Service"
                    >United States Postal Service</option
                  >
                  <option value="Water Transportation"
                    >Water Transportation</option
                  >
                  <option value="Transportation by Air"
                    >Transportation by Air</option
                  >
                  <option value="Pipelines, Except Natural Gas"
                    >Pipelines, Except Natural Gas</option
                  >
                  <option value="Transportation Services"
                    >Transportation Services</option
                  >
                  <option value="Communications">Communications</option>
                  <option value="Electric, Gas and Sanitary Services"
                    >Electric, Gas and Sanitary Services</option
                  >
                </optgroup>

                <optgroup
                  label="Wholesale Trade"
                  data-subtext="optgroup subtext"
                >
                  <option value="Wholesale Trade - Durable Goods"
                    >Wholesale Trade - Durable Goods</option
                  >
                  <option value="Wholesale Trade - Non-durable Goods"
                    >Wholesale Trade - Non-durable Goods</option
                  >
                </optgroup>
              </select>
            </div>

            <!--<div class="form-group">
                <label for="billingcycle">Billing Cycle</label>
                <span class="text-danger">*</span>
                <select class="form-select" name="" id="billingcycle">
                  <option value=""></option>
                  <option value="Trinco">Trinco</option>
                  <option value="">Singapore</option>
                </select>
              </div> -->

            <!--<div class="form-group">
                <label for="plan">Plan</label>
                <span class="text-danger">*</span>
                <select class="form-select" name="" id="plan">
                  <option value=""></option>
                  <option value="">Trinco</option>
                  <option value="">Singapore</option>
                </select>
              </div> -->

            <div class="form-group mb-1">
              <label for="confirmPromoCode">Promo Code (Optional)</label>
              <input
                type="text"
                class="form-control"
                id="confirmPromoCode"
                placeholder=""
                v-model="confirmPromoCode"
              />
            </div>

            <div class="mb-5 form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="agreeCheck1"
                required
                v-model="checkbox"
              />
              <label class="form-check-label" for="agreeCheck1">
                I agree to the
                <a href="/service-page" target="_blank">Terms of Service</a> &
                <a href="/privacy-policy" target="_blank">Privacy Policy</a>
              </label>
            </div>
          </div>
        </div>

        <div class="text-end">
          <button
            class="btn btn-primary btn-lg btn-block mt-3"
            id="btnSignUp"
            @click="signUp"
          >
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default {
  name: "Register",
  data() {
    return {
      fullname: "",
      phoneno: "",
      businessname: "",
      usertype: "",
      email: "",
      password: "",
      country: "",
      industry: "",
      confirmPromoCode: "-",
      checkbox: "yes",
    };
  },
  mounted() {},
  methods: {
    signUp() {
      if (this.fullname == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please enter your Full Name.",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.phoneno == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please enter your Phone No.",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.businessname == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please enter your Business Name.",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.usertype == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please Select your User Type",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.email == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please enter your Email",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.password == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please enter your Password",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.country == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please Select your Country",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.industry == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please Select your Industry",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      if (this.checkbox == "") {
        console.log("oki");
        Swal.fire({
          title: "Error!",
          text: "Please Select Check Box",
          icon: "error",
          confirmButtonText: "Cool",
        });
        return false;
      }

      //let loader = this.$loading.show();

      const headers = {
        "Content-Type": "application/json",
      };

      var query = this.$route.query;
      let plan = query["plan"];

      axios
        .post(
          "https://app.kenwynbooks.com/api/routes/create-user.php",
          {
            userName: this.fullname,
            phoneNo: this.phoneno,
            companyName: this.businessname,
            userEmail: this.email,
            userPassword: this.password,
            companyLocation: this.country,
            industry: this.industry,
            promoCode: this.confirmPromoCode,
            userPlan: plan,
            agreeToc: "y",
            userType: this.usertype,
            accessToken: "87654321",
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log(response);

          if (response.data.code == "500") {
            Swal.fire({
              title: "Error!",
              text: "User Email Already Exits.",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              location.reload();
            });
          } else {
            Swal.fire({
              title: "Success!",
              text: "Page will be reloaded.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              location.reload();
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //loader.hide();
        });
    },
  },
};
</script>

<style scoped>
.head {
  font-size: 50px;
  text-align: center;
}

.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}

.img-gradient {
  position: relative;
  display: inline-block;
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img {
  display: block;
}

.row {
  padding-bottom: 50px;
}

.card_one shadow {
  padding-bottom: 20px;
  border-radius: 1rem;
}

.card-img-top {
  max-width: 350px;
}

.phon1 {
  border: 1px solid #ced4da;
  /* padding: 1px; */
  border-radius: 6px;
  width: auto;
}
/* .phon1:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */
.phon1 input {
  display: inline-block;
  width: auto;
  border: none;
}
.phon1 input:focus {
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}

</style>
