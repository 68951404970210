<template>
  <div id="app">
    <HeaderNavBar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import HeaderNavBar from "@/components/HeaderNavBar";
import Footer from "@/components/Footer";

export default {
  name: "app",
  components: {
    HeaderNavBar,
    Footer,
  },

  data() {},
};
</script>

<style>
.nav {
  display: flex;
}
.nav p {
  padding: 0px 30px 0px 0px;
  font-size: 18px;
  color: #000;
}
.nav p:hover {
  opacity: 0.7;
}
.nav p a {
  text-decoration: none;
}
.sign-out {
  width: 160px;
  margin: 0 auto;
}
.navbar {
  position: fixed;
  display: flex;
  width: 100%;
}
</style>
