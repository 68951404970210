<template>
  <div class="container_Details">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-4">
        <img
          src="@/assets/kenwyn.png"
          width="210"
          height="100"
          alt="KeynwynBooks_Logo"
        />
        <br />
        <p class="comDes pt-3 text-secondary text-justify">
          Is a product of Kenwyn Accounting and IT Services Pte. Ltd., which is
          a consulting company providing services and technology solutions. We
          stand beside all businesses to help them shape and drive their
          transformation journey. We are always at the forefront of innovation.
        </p>
        <div class="pt-4">
          <a
            type="button"
            class="btn btn-outline-light"
            href="https://kenwynaccounting.com/privacy-policy"
            target="_blank"
          >
            <b> PRIVACY POLICY</b>
          </a>
        </div>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 pt-5">
        <h5 class="Link"><b>CONTACT US</b></h5>
        <p class="pt-3">
          <i
            class="fa fa-mobile fa-1x"
            style="color: white; font-size: 36px"
            aria-hidden="true"
            >&nbsp;&nbsp;</i
          ><span
            ><a
              href="https://api.whatsapp.com/send?phone=6597223997"
              target="_blank"
            >
              +65-97223997</a
            ></span
          >
        </p>
        <hr />

        <p class="pt-3">
          <i
            class="fa fa-envelope fa-1x"
            style="color: white; font-size: 36px"
            aria-hidden="true"
          ></i
          >&nbsp;&nbsp;<span
            ><a
              href="mailto:info@kenwynaccounting.com?subject=Accounting%20Services%20Required"
              >info@kenwynaccounting.com</a
            ></span
          >
        </p>
      </div>
      <div class="col-sm-12 col-md-4 col-lg-4 pt-5">
        <h5 class="Link"><b>SERVICES</b></h5>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;</i
          ><a
            href="https://kenwynaccounting.com/accounting-services#"
            span
            class="text-secondary"
            target="_blank"
            >Company Registration</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services#"
            span
            class="text-secondary"
            target="_blank"
            >Keep Booking</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services#"
            span
            class="text-secondary"
            target="_blank"
            >Payroll Management</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://api.whatsapp.com/send?phone=6597223997"
            span
            class="text-secondary"
            target="_blank"
            >24/7 Support Desk</a
          >
        </p>
        <p class="pt-3">
          <i class="fa fa-angle-right" style="color: grey" aria-hidden="true"
            >&nbsp;&nbsp;<a href=""></a></i
          ><a
            href="https://kenwynaccounting.com/accounting-services#"
            span
            class="text-secondary"
            target="_blank"
            >Digital Marketing</a
          >
        </p>
      </div>

      <!--  <div class="col">
        <h3>Newsletter</h3>
        <ul>
          <li>Stay up to date with the latest news about DashBod.</li>
          <li>
            <input type="email" name="email" required="requierd" />
          </li>
          <input type="submit" name="email" value="Subscribe Now " disabled />
        </ul>
      </div>-->
    </div>
    <hr />
    <div class="copyRightCls text-center">
      <!-- <p>
        © 2020 All rights reserved. DashBod is an integrated Accounting and
        Human Resources cloud-based software solution, and is developed by
        SMECEN, a wholly-owned subsidiary of ASME (The Association of Small &
        Medium Enterprises) Enterprise Holdings Pte Ltd.
      </p>-->
    </div>
  </div>
</template>
<style scoped>
.container_Details {
  background: rgb(43, 42, 42);
  padding: 30px;
}
.Link {
  color: white;
}
a {
  text-decoration: none;
  color: aliceblue;
}
.copyRightCls {
  color: rgb(165, 160, 160);
}
hr {
  background: rgb(165, 160, 160);
  opacity: 0.2;
}
.comDes {
  color: white;
}
</style>
