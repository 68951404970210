import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Homepage'
import Features from '../components/Features'
import Pricing from '../components/Pricing'
import Partners from '../components/Partners'
import About from '../components/About'
import Service from '../components/Service'
import Contact from '../components/Contact'
import HomeImage from '../components/HomeImage'
import Partner from '../components/Partner'
import Register from '../components/Register'
import PrivacyPolicy from '../components/PrivacyPolicy'


//import vue store
// import store from '@/store/index' 

const routes = [
    { path: '/', component: Home },
    { path: '/features', component: Features },
    { path: '/pricing', component: Pricing },
    { path: '/partners', component: Partners },
    { path: '/about-page', component: About },
    { path: '/service-page', component: Service },
    { path: '/contact-us-page', component: Contact },
    { path: '/home-image', component: HomeImage },
    { path: '/partner-page', component: Partner },
    { path: '/register', component: Register },
    { path: '/privacy-policy', component: PrivacyPolicy },

]

const router = createRouter({
    linkExactActiveClass: "router-active-class", // defined in App.vue file
    history: createWebHistory(),
    routes

})




export default router