<template>
  <img class="first-slide" src="@/assets/bok.jpg" />
  <div class="bg-text">
    <h2 class="head"><b>KenwynBooks</b></h2>
    <p>
      We are a company with global vision to offer solutions in IT, Business
      Consulting and Outsourcing Services. We demonstrate a high-level of
      commitment in serving our customers, providing innovative ideas and
      technology solutions to meet business needs.
    </p>
    <div>
      <button type="button" class="btn btn-success"><b>SERVICES</b></button>
    </div>
  </div>
</template>
<style scoped>
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.head {
  font-size: 60px;
  color: white;
  text-align: center;
}
.first-slide {
  width: 1120px;
}
</style>
