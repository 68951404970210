 <template>
  <!--partner image-->
  <div class="img-gradient">
    <img class="first-slide" src="@/assets/bok.jpg" />
  </div>
  <div class="bg-text">
    <h2 class="head"><b>PARTNER</b></h2>
    <p class="pt-5"></p>
  </div>
  <!--partner image-->

  <!--partner content -->
  <div class="pl-3 pt-2">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12 pr-3">
        <div class="h5 pt-5 partnerPage"><b>WHY WORK WITH US ?</b></div>
        <p class="pt-3 text-center">
          Our B2B marketing processes are based on solid marketing strategies
          that compliment your current marketing and sales activities. Our B2B
          marketing expertise can enhance your brand, develop your market offer
          and help your sales team connect your product, service or solution to
          the right audience.
        </p>
        <div class="pt-4 text-center">
          <span class="pl-3">Experience</span>
          <br />

          <span class="pl-3">Speciality</span>
          <br />
          <span class="pl-3">Professionalism</span>
          <br />
          <span class="pl-3"> Result Driven</span>
        </div>
      </div>
    </div>
    <!--haveQues start-->
    <br />
    <div class="row pt-5 pb-5 pr-5 pt-3 rowColor">
      <div class="col-md-8 col-sm-8 col-lg-8 rowClr">
        <div>
          <h5 class="text-light pl-2"><b>HAVE QUESTIONS? LET US KNOW!</b></h5>
          <div>
            <p class="pl-2 text-light text-justify">
              Have questions on your accounting, corporate secretarial and
              corporate and income tax matters? Book and receive a free
              consultation session with us today! Let us hear you and be your
              solutions in every of your accounting, corporate secretarial and
              income tax matters.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-lg-4 pl-5 rowClr">
        <div class="pt-5">
          <button type="button" class="btn btn-outline-light">
            CONTACT US
          </button>
        </div>
      </div>
    </div>
    <!--haveQues end-->

    <!--worldCls Support added-->
    <div class="row pt-5 text-justify">
      <div class="col-md-7 col-sm-12 col-lg-7 pb-3">
        <div class="h5 text-center">&nbsp;&nbsp;<b>WORLD-CLASS SUPPORT</b></div>
        <br />Partner onboarding, technical training, dedicated account
        management, and ongoing support for new features and changes..

        <div class="h5 text-center pt-4">
          &nbsp;&nbsp;<b>MARKETING PROMOTION AND LEAD GENERATION</b>
        </div>
        <br />KCS actively works to promote our partners through various
        marketing initiatives and promotional campaigns. Project leads are then
        given to our partners.

        <div class="h5 text-center pt-3">
          &nbsp;&nbsp;<b>HOW TO GET STARTED</b>
        </div>
        <br />KCS actively works to promote our partners through various
        marketing initiatives and promotional campaigns. Project leads are then
        given to our partners.
      </div>
      <div class="col-md-5 col-sm-12 col-lg-5">
        <img
          class="firstImg pb-2"
          style="text-align: center"
          src="@/assets/book-1.jpg"
        />
      </div>
    </div>
    <!--worldCls Support added-->
  </div>
  <!--partner content -->
</template>
<style  scoped>
.first-slide {
  width: 100%;
  height: 20%;
}
.firstImg {
  width: 500px;
}
.rowColor {
  background-color: green;
}

.head {
  font-size: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.img-gradient {
  position: relative;
  display: inline-block;
}

/* 
#002f4b,#dc4225 
Convert HEX to RGBA - http://hex2rgba.devoth.com/
*/
.img-gradient:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;

  background: linear-gradient(
    to bottom,
    rgba(2, 1, 23, 0.619) 100%,
    rgba(2, 1, 23, 0.619) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}
.img-gradient img {
  display: block;
}
@media only screen and (max-width: 600px) {
  .first-slide {
    max-width: 100%;
    height: 700px;
  }
  .head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 300px) {
  .first-slide {
    max-width: 100%;
    height: 400px;
  }
  .head {
    font-size: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .first-slide {
    max-width: 100%;
    height: 900px;
  }
  .head {
    font-size: 30px;
  }
}

.partnerPage {
  text-align: center;
}
</style>