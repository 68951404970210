<template>
  <div>
    <div class=" ">
      <div class="img-container">
        <div class="img-overlay"></div>
        <img class="first-slide img-fluid" src="@/assets/lap.jpg" />
        <div class="bg-text position-absolute top-5 start-5">
          <h3 class="text-center mt-5 h1 text-light text-head pt-5">
            <b>PRIVACY POLICY</b>
          </h3>
        </div>
      </div>
    </div>
  </div>
  <div class="m-3">
    <div class="row pt-5 pl-3">
      <div class="col-md-12 col-sm-12 col-lg-12">
        <div class="accounding pt-5">
          <b class="pt-5">DATA PROTECTION POLICY</b>
        </div>
        <div style="margin: 40px" class="terms">
          <p>
            This document (&ldquo;Policy&rdquo;) applies to all personal data
            collected by Kenwyn Accounting and IT Services Pte. Ltd. and all its
            related companies (collectively referred to herein as
            &ldquo;KAITS&rdquo;, &ldquo;us&rdquo;, &ldquo;we&rdquo; or
            &ldquo;our&rdquo;).
          </p>

          <p>
            The purpose of this Policy is to inform you of how we manage
            Personal Data which is subject to the personal data protection
            principles under the Singapore Personal Data Protection Act (No. 26
            of 2012) (&ldquo;the Act&rdquo;) or similar personal data protection
            principles applicable to companies which provide company formation
            and administration services.
          </p>

          <p>
            By interacting with us, submitting information to use, or signing up
            for any products and services offered by us, you agree and consent
            to KAITS, as well as our authorised representatives and/or agents
            collecting, using, disclosing and sharing amongst themselves your
            Personal Data, and disclosing such Personal Data to KAITS&rsquo;
            authorised service providers and relevant third parties in the
            manner set forth in this Policy.
          </p>

          <p>
            This Policy supplements but does not supersede nor replace any other
            consents you may have previously provided to KAITS in respect of
            your Personal Data, and your consents herein are additional to any
            rights which KAITS may have at law to collect, use or disclose your
            Personal Data.
          </p>

          <p>
            KAITS may from time to time update this Policy to ensure that this
            Policy is consistent with our future developments, industry trends
            and/or any changes in legal or regulatory requirements. Subject to
            your rights at law, you agree to be bound by the prevailing terms of
            this Policy as updated from time to time on our website [provide
            link].
          </p>
          <h5>1.Personal Data</h5>
          <p>
            <b>1.1</b>
            In this Policy, &ldquo;Personal Data&rdquo; refers to any data,
            whether true or not, about an individual who can be identified (a)
            from that data; or (b) from that data and other information to which
            we have or are likely to have access, including data in our records
            as may be updated from time to time.
          </p>
          <p>
            <b>1.2</b>
            Examples of such Personal Data you may provide to us include
            (depending on the nature of your interaction with us) your name,
            NRIC, passport or other identification number, age or birth date,
            mobile number(s), telephone number(s), facsimile number(s), mailing
            address, email address, network data and any other information
            relating to any individuals which you have provided us via our
            interaction with you.
          </p>
          <h5>2. Collection of Personal Data</h5>
          <p>
            <b>2.1</b> Generally, we collect Personal Data in the following
            ways:
          </p>

          <p>
            2.1.1 when you submit any forms relating to any of our products and
            services;
          </p>

          <p>
            2.1.2 when you enter into any agreement or provide other
            documentation or information in respect of your interactions with
            us, or when you use our services;
          </p>

          <p>
            2.1.3 when you interact with our staff, for example, via telephone
            calls, letters, face-to-face meetings, social media platforms and
            emails;
          </p>

          <p>
            2.1.4 when you interact with us via any of our websites or use
            services on any of our websites;
          </p>

          <p>
            2.1.5 when you request that we contact you or request that you be
            included in an email or other mailing list;
          </p>

          <p>
            2.1.6 when you respond to our promotions, initiatives or to any
            request for additional Personal Data;
          </p>

          <p>
            2.1.7 when your images are captured by us via CCTV cameras while you
            are within our premises, or via photographs or videos taken by us or
            our representatives when you attend events at our premises;
          </p>

          <p>
            2.1.8 when you are contacted by, and respond to, our marketing
            representatives;
          </p>

          <p>
            2.1.9 when we receive references from business partners and third
            parties, for example, where you have been referred by them;
          </p>

          <p>
            2.1.10 when you engage us through any social media on the Internet
            and on the social media itself;
          </p>

          <p>
            2.1.11 when we seek information from third parties about you in
            connection with the products and services you have applied for;
            and/or
          </p>

          <p>
            2.1.12 when you submit your Personal Data to us for any other
            reason.
          </p>

          <p>
            <b>2.2</b>
            If you provide us with any Personal Data relating to a third party
            (eg. information of your spouse, children, parents, and/or
            employees), by submitting such information to us, you represent and
            warrant to us that you have obtained the consent of the third party
            to provide us with their Personal Data for the respective purposes.
          </p>
          <h5>
            3. Purposes for Collection, Use and Disclosure of Your Personal Data
          </h5>

          <p>
            <b>3.1</b>
            Generally, KAITS collects, uses and discloses your Personal Data for
            the following purposes:
          </p>

          <p>3.1.1 responding to your queries, feedback and requests;</p>

          <p>3.1.2 verifying your identity and processing payments;</p>

          <p>
            3.1.3 managing the administrative and business operations of KAITS
            and complying with internal policies and procedures;
          </p>

          <p>
            3.1.4 providing updates and other communications on developments
            relating to KAITS;
          </p>

          <p>
            3.1.5 facilitating business asset transactions (which may extend to
            any mergers, acquisitions or asset sales) involving KAITS;
          </p>

          <p>
            3.1.6 matching any Personal Data held which relates to you for any
            of the purposes listed herein;
          </p>

          <p>
            3.1.7 preventing, detecting and investigating crime and analysing
            and managing commercial risks;
          </p>

          <p>
            3.1.8 facilities management (including but not limited to
            maintaining the security of our premises);
          </p>

          <p>
            3.1.9 managing the safety and security of our premises and services
            (including but not limited to carrying out CCTV surveillance);
          </p>

          <p>
            3.1.10 in connection with any claims, actions or proceedings
            (including but not limited to drafting and reviewing documents,
            transaction documentation, obtaining legal advice, and facilitating
            dispute resolution), and/or protecting and enforcing our contractual
            and legal rights and obligations;
          </p>

          <p>
            3.1.11 conducting investigations relating to disputes, billing or
            fraud;
          </p>

          <p>
            3.1.12 managing and preparing reports on incidents and accidents;
          </p>

          <p>
            3.1.13 meeting or complying with any applicable rules, laws,
            regulations, codes of practice or guidelines issued by any legal or
            regulatory bodies which have jurisdiction over KAITS (including but
            not limited to responding to regulatory complaints, disclosing to
            regulatory bodies and conducting audit checks, due diligence and
            investigations);
          </p>

          <p>
            3.1.14 financial reporting, regulatory reporting, management
            reporting, risk management, audit and record keeping purposes;
          </p>

          <p>3.1.15 project management;</p>

          <p>3.1.16 providing media announcements and responses; and/or</p>

          <p>3.1.17 any other purpose reasonably related to the aforesaid.</p>

          <p>
            <b>3.2</b>
            In addition, KAITS collects, uses and discloses your Personal Data
            for the following purposes, depending on the nature of your
            relationship with us:
          </p>

          <p><b>3.2.1</b> If you are a client of KAITS:</p>

          <p>
            3.2.1.1 assessing and processing any applications or requests made
            by you for products and services offered by KAITS, including
            corporate secretarial services, incorporation services, tax and
            accounting services and payroll services;
          </p>

          <p>
            3.2.1.2 requesting feedback or participation in surveys, as well as
            conducting market research and/or analysis for statistical,
            profiling or other purposes for us to review, develop and improve
            the quality of our products and services;
          </p>

          <p>
            3.2.1.3 facilitating the continuation or termination of the
            relationship and the provision of services to you in accordance with
            the mandate that you have agreed with us;
          </p>

          <p>
            3.2.1.4 client relationship management, including sending
            information relating to our services and business, services offered
            by third parties, event invitations, newsletters or publications;
          </p>

          <p>
            3.2.1.5 archival management (including but not limited to warehouse
            storage and retrievals); and/or
          </p>

          <p>3.2.1.6 any other purpose reasonably related to the aforesaid.</p>

          <p><b>3.2.2</b> If you are an existing employee of KAITS:</p>

          <p>
            3.2.2.1 providing remuneration, reviewing salaries and bonuses,
            conducting salary benchmarking reviews, appraisals and evaluation;
          </p>

          <p>3.2.2.2 staff orientation and entry processing;</p>

          <p>
            3.2.2.3 administrative and support processes relating to your
            employment, including its management and termination, as well as
            staff benefits, including manpower, business continuity and
            logistics management or support, processing expense claims, medical
            insurance applications, medical services, leave administration,
            training, learning and talent development, and planning and
            organising corporate events;
          </p>

          <p>
            3.2.2.4 providing you with tools and/or facilities to enable or
            facilitate the performance of your duties;
          </p>

          <p>
            3.2.2.5 compiling and publishing internal directories and emergency
            contact lists for business continuity;
          </p>

          <p>
            3.2.2.6 conducting analytics and research for human resource
            planning and management, and for us to review, develop, optimise and
            improve work-related practices, environment and productivity;
          </p>

          <p>
            3.2.2.7 administering cessation or termination processes; and/or
          </p>

          <p>3.2.2.8 any other purpose reasonably related to the aforesaid.</p>

          <p>
            <b>3.2.3</b>
            If you submit an application to us as a candidate for an employment
            position:
          </p>

          <p>3.2.3.1 managing and verifying your application;</p>

          <p>
            3.2.3.2 performing background checks, and verifying all your
            employment details and qualifications;
          </p>

          <p>
            3.2.3.3 providing or obtaining employee references and for
            background screening;
          </p>

          <p>
            3.2.3.4 assessing your suitability for the position applied for;
            and/or
          </p>

          <p>3.2.3.5 any other purposes relating to any of the above.</p>

          <p>
            <b>3.2.4</b>
            If you do not interact with us in the manners specified above, we
            may collect, use and/or disclose your Personal Data for the
            following purposes, in accordance with the Act:
          </p>

          <p>
            3.2.4.1 the purposes for which we have specifically obtained your
            consent; and/or
          </p>

          <p>
            3.2.4.2 the purposes for which you have provided your Personal Data
            to us.
          </p>

          <p>
            <b>3.3</b>
            If you have provided your Singapore telephone number(s) and have
            indicated that you consent to receiving marketing or promotional
            information via your Singapore telephone number(s), then from time
            to time, KAITS may contact you using such Singapore telephone
            number(s) (including via voice calls, text, fax or other means) with
            information about our products and services.
          </p>

          <p>
            <b>3.4.</b>
            When the need arises for Personal Data to be collected, used or
            disclosed for purposes other than the ones originally consented for,
            we will obtain your fresh consent in that regard, where necessary,
            in accordance with the Act.
          </p>

          <p>
            <b>3.5</b>
            As KAITS relies on your Personal Data to provide products and
            services to you, you should ensure that all Personal Data submitted
            to us is complete, accurate, true and correct.
          </p>
          <h5>4. Disclosure of Personal Data</h5>

          <p>
            <b>4.1</b>
            KAITS will take reasonable steps to protect your Personal Data
            against unauthorised disclosure. Subject to the provisions of any
            applicable law, your Personal Data may be disclosed by KAITS, for
            the purposes listed above (where applicable), to the following
            entities or parties, whether they are located overseas or in
            Singapore:
          </p>

          <p>4.1.1 our existing or future related companies;</p>

          <p>
            4.1.2 agents, contractors or third party service providers who
            provide operational services to KAITS, such as courier services,
            telecommunications, information technology, payment, printing,
            technical services, training, market research, call centre,
            security, employee recognition, storage and archival or other
            services to KAITS;
          </p>

          <p>
            4.1.3 any business partner, investor, assignee or transferee (actual
            or prospective) to facilitate business asset transactions (which may
            extend to any merger, acquisition or asset sale) involving KAITS;
          </p>

          <p>
            4.1.4 banks, financial institutions, credit card companies and their
            respective service providers;
          </p>

          <p>
            4.1.5 in the event of default or disputes, any debt collection
            agencies or dispute resolution centres (whether in Singapore or
            otherwise);
          </p>

          <p>
            4.1.6 any liquidator, receiver, official assignee/trustee, judicial
            manager or any other person appointed under or pursuant to any
            applicable law or court order in connection with the bankruptcy,
            liquidation, winding up, judicial management or any other analogous
            process in respect of any individual, company or business;
          </p>

          <p>
            4.1.7 our professional advisors such as consultants, auditors and
            lawyers;
          </p>

          <p>
            4.1.8 any judicial, administrative or regulatory body, any
            government or public agency, statutory boards or authorities or law
            enforcement bodies or any agents thereof, having jurisdiction over
            KAITS; and/or
          </p>

          <p>
            4.1.9 any other person in connection with the purposes set forth
            above.
          </p>

          <h5>5. Retention of Personal Data</h5>

          <p>
            <b>5.1</b>
            KAITS will retain your Personal Data for only as long as the
            purposes for which such data is collected or used continues, or
            where necessary for our legal or business purposes
            (&ldquo;Purposes&rdquo;).
          </p>

          <p>
            <b>5.2</b>
            KAITS will take all reasonable steps to ensure that all Personal
            Data is destroyed or permanently deleted if it is no longer required
            for those Purposes.
          </p>

          <h5>6. Use of Cookies</h5>

          <p>
            <b>6.1</b>
            A cookie is a small piece of information that is placed on your
            computer when you visit certain websites. The cookies placed by the
            servers hosting our websites are readable only by us, and cookies
            cannot access, read or modify any other data on an electric device,
            nor does it capture any data which allows us to identify you
            individually. The data collected by the cookies will be used for the
            purpose of improving your browsing experience on our websites and to
            enable KAITS to serve you better.
          </p>

          <p>
            <b>6.2</b>
            Should you wish to disable the cookies associated with these
            technologies, you may do so by changing the setting on your browser.
            However, you may not be able to enter certain part(s) of our
            websites.
          </p>

          <h5>7. Data Security</h5>

          <p>
            <b>7.1</b>
            KAITS will take reasonable efforts to protect Personal Data in our
            possession or our control by making reasonable security arrangements
            to prevent unauthorised access, collection, use, disclosure,
            copying, modification, disposal or similar risks.
          </p>

          <h5>8. Links to Other Websites</h5>

          <p>
            <b>8.1</b>
            Our websites may contain links to other websites operated by third
            parties. As KAITS does not have any control over such third party
            websites, we cannot be responsible for the privacy practices of
            websites operated by third parties that are linked to our websites.
            Once you have left our websites, you should check the applicable
            data protection policy of the third party to determine how they will
            handle any information they collect from you.
          </p>

          <h5>9. Contacting Us</h5>

          <p><b>9.1</b> If you:</p>

          <p>
            9.1.1 have any questions or feedback relating to your Personal Data
            or our Policy;
          </p>

          <p>
            9.1.2 would like to withdraw your consent to any use of your
            Personal Data as set out in this Policy; or
          </p>

          <p>
            9.1.3 would like to obtain access and make corrections to your
            Personal Data records, you can contact our Data Protection Officer
            via info@kenwynaccounting,com or +65 9722 3997.
          </p>

          <p>
            <b>9.2</b>
            If you withdraw your consent to any or all use or disclosure of your
            Personal Data, depending on the nature of your request, KAITS may
            not be in a position to continue to provide our products or services
            to you or administer any contractual relationship in place. Such
            withdrawal may also result in the termination of any agreement you
            may have with us. KAITS&rsquo; legal rights and remedies are
            expressly reserved in such event.
          </p>

          <h5>10. Governing Law</h5>

          <p>
            <b>10.1</b>
            This Policy and your use of this website shall be governed in all
            respects by the laws of Singapore.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.first-slide {
  width: 100%;
  max-height: 500px;
  background-size: cover;
  z-index: 0;
}

.accounding {
  font-size: 30px;
  color: rgb(8, 147, 29);
  text-align: center;
  font-size: 40px;
}
.rowColor {
  background-color: green;
  color: white;
}
.head {
  font-size: 50px;
  padding-top: 50px;
  text-align: center;
}
.bg-text {
  color: white;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}

.img-container {
  max-width: 100%;
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index: 1;
}
</style>
